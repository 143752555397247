import Header from "../component/Header";

const Profile=()=>
{
    return(
        <>
<Header/>
<div>
  <div className="line" />
  <div className="container">
    <div className="row">
      <div className="col-12 my-4 home">
        <a href="#" className="btn btn-primary px-4 rounded-pill">Back to home</a>
      </div>
      <div className="col-12">
        <div id="carouselHero" className="carousel slide mt-0 carousel-fade" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={1} aria-label="Slide 2" />
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={2} aria-label="Slide 3" />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="assets/images/bnr_01.jpg" alt />
            </div>
            <div className="carousel-item">
              <img src="assets/images/bnr_02.jpg" alt />
            </div>
            <div className="carousel-item">
              <img src="assets/images/bnr_03.jpg" alt />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-9 col-lg-7 mx-auto">
        <div className="profile">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <figure>
                <img src="assets/images/atr_pic.jpg" alt />
              </figure>
              <h4>My Profile</h4>
              <p>In this section, you can select a unique AI-generated profile image and create your username.</p>
              <br />
            </div>
          </div>
          <form>
            <div className="mb-3">
              <label className="form-label">Profile Image Category:</label>
              <select className="form-select">
                <option selected>Robot</option>
                <option>Alien</option>
                <option>Ape</option>
                <option>Dog</option>
                <option>Cat</option>
                <option>Boy</option>
                <option>Girl</option>
              </select>
              <div className="row gx-1 mt-1 justify-content-between">
                <div className="col-6 col-md-4">
                  <button type="button" className="btn btn-primary"><img src="assets/images/icn_gen.png" alt /> Generate</button>
                </div>
                <div className="col-6 col-md-4 d-grid">
                  <button type="button" className="btn btn-outline-primary">Previous Image</button>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Create Username:</label>
              <input className="form-control" type="text" placeholder="Create Username Not More than 12 Character" />
              <div className="col-6 col-md-4 mt-1">
                <button type="button" className="btn btn-primary"><img src="assets/images/icn_gen.png" alt /> Create</button>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Bind Email Address</label>
              <input className="form-control mb-1" type="text" placeholder="Bind Email Address" />
              <div className="position-relative">
                <input className="form-control" type="password" placeholder="Enter OTP" />
                <div className="position-absolute me-1 top-0 end-0">
                  <button type="button" className="btn btn-primary btn-sm px-3">Request OTP</button>
                </div>
              </div>
              <div className="col-6 col-md-4 mt-5">
                <button type="button" className="btn btn-primary"><img src="assets/images/icn_gen.png" alt /> Bind</button>
              </div>
            </div>
          </form>
        </div>                
      </div>
    </div>
  </div>
  <nav className="fixed-bottom">
    <div className="container">
      <div className="row gx-2">
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_trade.svg" alt /></figure>
            Trade
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_nodes.svg" alt /></figure>
            Nodes
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_card.svg" alt /></figure>
            Crypto Card
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_play.svg" alt /></figure>
            Play
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icm_launch.svg" alt /></figure>
            LaunchPad
          </a>
        </div>
      </div>
    </div>
  </nav>
</div>
 
        </>
    )
};
export default Profile;
    