import Header from "../component/Header";

const Nodes=()=>
{
    return(
        <>
          
    <Header/>
  <div>
  <div className="line" />
  <div className="container">
    <div className="row">
      <div className="col">
        <div id="carouselHero" className="carousel slide carousel-fade" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={1} aria-label="Slide 2" />
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={2} aria-label="Slide 3" />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="assets/images/bnr_01.jpg" alt />
            </div>
            <div className="carousel-item">
              <img src="assets/images/bnr_02.jpg" alt />
            </div>
            <div className="carousel-item">
              <img src="assets/images/bnr_03.jpg" alt />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="nodes">
      <div className="row">
        <div className="col">
          <h4>Genesis Nodes</h4>
          <div className="box mb-3">
            <div className="amount">
              Amount
              <div className="row align-items-center">
                <div className="col-10"><h3>500,000.00</h3></div>
                <div className="col-2"> <img src="assets/images/cnx.png" alt /></div>
              </div>
            </div>
            <p>Staking Term: <span className="ms-4">24 Month</span><br />
              Expected Returns:<br />
              1,000,000.00000000<br />
              Daily: <span className="ms-4">1,000.000000000</span><br />
              Scratch &amp; Wins: <span className="ms-4">2 Tickets</span> <img src="assets/images/pack.png" className="pack" alt /></p>
            <div className="d-grid gap-2">
              <a href="#" className="btn btn-approve">Approve CNX</a>
              <a href="#" className="btn btn-stake">Stake</a>
            </div>
          </div>
        </div>
        <div className="col mb-3">
          <h4>Founder Nodes</h4>
          <div className="box">
            <div className="amount">
              Amount
              <div className="row align-items-center">
                <div className="col-10"><h3>500,000.00</h3></div>
                <div className="col-2"> <img src="assets/images/cnx.png" alt /></div>
              </div>
            </div>
            <p>Staking Term: <span className="ms-4">24 Month</span><br />
              Expected Returns:<br />
              1,000,000.00000000<br />
              Daily: <span className="ms-4">1,000.000000000</span><br />
              Scratch &amp; Wins: <span className="ms-4">2 Tickets</span> <img src="assets/images/pack.png" className="pack" alt /></p>
            <div className="d-grid gap-2">
              <a href="#" className="btn btn-approve">Approve CNX</a>
              <a href="#" className="btn btn-stake">Stake</a>
            </div>
          </div>
        </div>
        <div className="col mb-3">
          <h4>Community Nodes</h4>
          <div className="box">
            <div className="amount">
              Amount
              <div className="row align-items-center">
                <div className="col-10"><h3>500,000.00</h3></div>
                <div className="col-2"> <img src="assets/images/cnx.png" alt /></div>
              </div>
            </div>
            <p>Staking Term: <span className="ms-4">24 Month</span><br />
              Expected Returns:<br />
              1,000,000.00000000<br />
              Daily: <span className="ms-4">1,000.000000000</span><br />
              Scratch &amp; Wins: <span className="ms-4">2 Tickets</span> <img src="assets/images/pack.png" className="pack" alt /></p>
            <div className="d-grid gap-2">
              <a href="#" className="btn btn-approve">Approve CNX</a>
              <a href="#" className="btn btn-stake">Stake</a>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div id="carouselHero1" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselHero1" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
              <button type="button" data-bs-target="#carouselHero1" data-bs-slide-to={1} aria-label="Slide 2" />
              <button type="button" data-bs-target="#carouselHero1" data-bs-slide-to={2} aria-label="Slide 3" />
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="assets/images/bnr_01.jpg" alt />
              </div>
              <div className="carousel-item">
                <img src="assets/images/bnr_02.jpg" alt />
              </div>
              <div className="carousel-item">
                <img src="assets/images/bnr_03.jpg" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="try">
      <div className="box mb-3">
        <a href="#" className="close"><img src="assets/images/close.png" alt /></a>
        <a href="#" data-bs-toggle="modal" data-bs-target="#mydaz">
          <figure>
            <img src="assets/images/bot.png" alt />
          </figure>                
          Try Out Mydaz<br /> AI Trading Bot
        </a>
      </div>
      <div className="box">
        <a href="#" className="close"><img src="assets/images/close.png" alt /></a>
        <a href="#" data-bs-toggle="modal" data-bs-target="#farming">
          <figure>
            <img src="assets/images/farm.png" alt />
          </figure>
          Social<br /> Farming
        </a>
      </div>
    </div>
  </div>
  <nav className="fixed-bottom">
    <div className="container">
      <div className="row gx-2">
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_trade.svg" alt /></figure>
            Trade
          </a>
        </div>
        <div className="col">
          <a href="#" className="active">
            <figure><img src="assets/images/icn_nodes.svg" alt /></figure>
            Nodes
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_card.svg" alt /></figure>
            Crypto Card
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_play.svg" alt /></figure>
            Play
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icm_launch.svg" alt /></figure>
            LaunchPad
          </a>
        </div>
      </div>
    </div>
  </nav>
  {/* Modal Mydaz */}
  <div className="modal fade" id="mydaz" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="Mydaz" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-0">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="text-center mb-5">
            <figure>
              <img src="assets/images/mydaz.png" alt />
            </figure>
            <h4>You are going to enter A New Site</h4>
            <a href="#" className="btn btn-primary mt-4 rounded-pill">Enter</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Modal Farming */}
  <div className="modal fade" id="farming" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="Farming" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-0">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="social">
            <h4 className="text-center">Accumulated 5,000 Social Points,<br />
              To Receive a Scratch &amp; Win Ticket</h4>
            <div className="row head mb-3">
              <div className="col">
                Social Points Accumulated:<br />
                500
              </div>
              <div className="col-auto">
                <u>Redeem</u>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Follow Advantio on X<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Follow</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Share Advantio’s Official<br />
                  Launch Teaser Post<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Follow</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Purchase a Lottery Ticket<br />
                  <span>5000 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Purchase</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Share Advantio’s Sure<br />
                  Win Lottery Post<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Retweet</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Join Our Telegram<br />
                  Channel<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Join Now</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Purchase Genesis Nodes<br />
                  <span>15,000 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Purchase</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    )
};
export default Nodes;
    