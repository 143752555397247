import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Trade from "./advantio/Trade";
import Nodes from "./advantio/Nodes";
import Cryptocard from "./advantio/Cryptocard";
import Play from "./advantio/Play";
import Launchpad from "./advantio/Launchpad";
import Myticket from "./advantio/Myticket";
import Profile from "./advantio/Profile";

function App() {
  return ( 
   <>
    <BrowserRouter>
      <Routes>
          <Route index element={<Trade />} />
          <Route path="Trade" element={<Trade />} />
          <Route path="Nodes" element={<Nodes />} />
          <Route path="Cryptocard" element={<Cryptocard />} />
          <Route path="Play" element={<Play />} />
          <Route path="Launchpad" element={<Launchpad />} />
          <Route path="Myticket" element={<Myticket />} />
          <Route path="Profile" element={<Profile />} />
      </Routes>
    </BrowserRouter>
   </>
  );
}

export default App;
