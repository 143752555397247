import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Header from "../component/Header";

const Play=()=>
{
    const options =
    {
      smartSpeed: 1000,
      margin: 25,
      loop: true,
      dots: false,
      nav: true,
      responsive:{
        0:{
            items:1
        },
        600:{
            items:3
        },
        1000:{
            items:3
        }
      }
    }

    return(
        <>
        <Header/>
     <div>
  <div className="line" />
  <div className="container">
    <div className="head">
      <div className="row">
        <div className="col-12">
          <h3>Countdown To Opening</h3>
        </div>
      </div>
    </div>
    <div className="countdown">
      <div className="row">            
        <div className="col-12">                    
          <div className="row gx-1">                    
            <div className="col">
              <div className="row mb-3 gx-0">
                <div className="col"><h2>H</h2></div>
                <div className="col"><h2>H</h2></div>
              </div>
              <div className="row gx-0">
                <div className="col"><div className="count"><h1>2</h1></div></div>
                <div className="col"><div className="count"><h1>3</h1></div></div>
              </div>                        
            </div>
            <div className="col">
              <div className="row mb-3 gx-0">
                <div className="col"><h2>M</h2></div>
                <div className="col"><h2>M</h2></div>
              </div>
              <div className="row gx-0">
                <div className="col"><div className="count"><h1>5</h1></div></div>
                <div className="col"><div className="count"><h1>9</h1></div></div>
              </div> 
            </div>
            <div className="col">
              <div className="row mb-3 gx-0">
                <div className="col"><h2>S</h2></div>
                <div className="col"><h2>S</h2></div>
              </div>
              <div className="row gx-0">
                <div className="col"><div className="count"><h1>5</h1></div></div>
                <div className="col"><div className="count"><h1>9</h1></div></div>
              </div> 
            </div>
          </div>
        </div>
        <div className="col-md-9 win mx-auto">
          <ul className="nav nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-lottery-tab" data-bs-toggle="pill" data-bs-target="#pills-lottery" type="button" role="tab" aria-controls="pills-lottery" aria-selected="true">Lottery</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-scratch-tab" data-bs-toggle="pill" data-bs-target="#pills-scratch" type="button" role="tab" aria-controls="pills-scratch" aria-selected="false">Scratch &amp; Win</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="tab-content" id="pills-tabContent">
      <div className="tab-pane fade show active" id="pills-lottery" role="tabpanel" aria-labelledby="pills-lottery-tab" tabIndex={0}>
        <div className="generate">
          <div className="balls">
            <div className="content">
              Each lottery ticket is generated by staking 20,000 CNX,<br />
              and every ticket is uniquely tied to a hashtag and wallet address.<br />
              A minimum of 100 tickets must be sold for a draw round to commence.<br />
              If the ticket threshold is not met, the entries will roll over to the next draw round.
            </div>
            <div className="d-sm-block d-md-none">
              <img src="assets/images/balls.png" className="w-100" alt />
            </div>                            
          </div>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>A</h3></div></div>
              <div className="col"><div className="nomber"><h3>A</h3></div></div>
              <div className="col"><div className="nomber"><h3>A</h3></div></div>
              <div className="col"><div className="nomber"><h3>A</h3></div></div>
              <div className="col"><div className="nomber"><h3>A</h3></div></div>
            </div>
          </div>
          <div className="text-center mt-4">
            <a href="#" className="btn btn-generate">Generate<br /><small>(01 Ticket)</small></a>
          </div>
          <br />
        </div>
       <div className="results">
    <div className="row">
    <div className="col-12">
      <h2>Draw Results</h2>
    </div>
    <OwlCarousel className='owl-theme draw-results' {...options}>
      <div className="item">
        <div className="box">
          <h3>Round #1</h3>
          <p>26/10/2024 07:00 PM GMT +8</p>
          <h4>First Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>Y</h3></div></div>
              <div className="col"><div className="nomber"><h3>2</h3></div></div>
              <div className="col"><div className="nomber"><h3>K</h3></div></div>
              <div className="col"><div className="nomber"><h3>5</h3></div></div>
              <div className="col"><div className="nomber"><h3>A</h3></div></div>
            </div>
          </div>
          <h4>Second Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>4</h3></div></div>
              <div className="col"><div className="nomber"><h3>8</h3></div></div>
              <div className="col"><div className="nomber"><h3>4</h3></div></div>
              <div className="col"><div className="nomber"><h3>Z</h3></div></div>
              <div className="col"><div className="nomber"><h3>D</h3></div></div>
            </div>
          </div>
          <h4>Third Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>T</h3></div></div>
              <div className="col"><div className="nomber"><h3>G</h3></div></div>
              <div className="col"><div className="nomber"><h3>1</h3></div></div>
              <div className="col"><div className="nomber"><h3>7</h3></div></div>
              <div className="col"><div className="nomber"><h3>B</h3></div></div>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="box">
          <h3>Round #2</h3>
          <p>26/10/2024 07:00 PM GMT +8</p>
          <h4>First Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>Y</h3></div></div>
              <div className="col"><div className="nomber"><h3>2</h3></div></div>
              <div className="col"><div className="nomber"><h3>K</h3></div></div>
              <div className="col"><div className="nomber"><h3>5</h3></div></div>
              <div className="col"><div className="nomber"><h3>A</h3></div></div>
            </div>
          </div>
          <h4>Second Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>4</h3></div></div>
              <div className="col"><div className="nomber"><h3>8</h3></div></div>
              <div className="col"><div className="nomber"><h3>4</h3></div></div>
              <div className="col"><div className="nomber"><h3>Z</h3></div></div>
              <div className="col"><div className="nomber"><h3>D</h3></div></div>
            </div>
          </div>
          <h4>Third Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>T</h3></div></div>
              <div className="col"><div className="nomber"><h3>G</h3></div></div>
              <div className="col"><div className="nomber"><h3>1</h3></div></div>
              <div className="col"><div className="nomber"><h3>7</h3></div></div>
              <div className="col"><div className="nomber"><h3>B</h3></div></div>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="box">
          <h3>Round #3</h3>
          <p>26/10/2024 07:00 PM GMT +8</p>
          <h4>First Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>Y</h3></div></div>
              <div className="col"><div className="nomber"><h3>2</h3></div></div>
              <div className="col"><div className="nomber"><h3>K</h3></div></div>
              <div className="col"><div className="nomber"><h3>5</h3></div></div>
              <div className="col"><div className="nomber"><h3>A</h3></div></div>
            </div>
          </div>
          <h4>Second Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>4</h3></div></div>
              <div className="col"><div className="nomber"><h3>8</h3></div></div>
              <div className="col"><div className="nomber"><h3>4</h3></div></div>
              <div className="col"><div className="nomber"><h3>Z</h3></div></div>
              <div className="col"><div className="nomber"><h3>D</h3></div></div>
            </div>
          </div>
          <h4>Third Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>T</h3></div></div>
              <div className="col"><div className="nomber"><h3>G</h3></div></div>
              <div className="col"><div className="nomber"><h3>1</h3></div></div>
              <div className="col"><div className="nomber"><h3>7</h3></div></div>
              <div className="col"><div className="nomber"><h3>B</h3></div></div>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="box">
          <h3>Round #4</h3>
          <p>26/10/2024 07:00 PM GMT +8</p>
          <h4>First Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>Y</h3></div></div>
              <div className="col"><div className="nomber"><h3>2</h3></div></div>
              <div className="col"><div className="nomber"><h3>K</h3></div></div>
              <div className="col"><div className="nomber"><h3>5</h3></div></div>
              <div className="col"><div className="nomber"><h3>A</h3></div></div>
            </div>
          </div>
          <h4>Second Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>4</h3></div></div>
              <div className="col"><div className="nomber"><h3>8</h3></div></div>
              <div className="col"><div className="nomber"><h3>4</h3></div></div>
              <div className="col"><div className="nomber"><h3>Z</h3></div></div>
              <div className="col"><div className="nomber"><h3>D</h3></div></div>
            </div>
          </div>
          <h4>Third Prize Winner</h4>
          <div className="tickets mx-auto">
            <div className="row gx-0">
              <div className="col"><div className="nomber"><h3>T</h3></div></div>
              <div className="col"><div className="nomber"><h3>G</h3></div></div>
              <div className="col"><div className="nomber"><h3>1</h3></div></div>
              <div className="col"><div className="nomber"><h3>7</h3></div></div>
              <div className="col"><div className="nomber"><h3>B</h3></div></div>
            </div>
          </div>
        </div>
      </div>
    </OwlCarousel>
  </div>
</div>
      </div>
      <div className="tab-pane fade" id="pills-scratch" role="tabpanel" aria-labelledby="pills-scratch-tab" tabIndex={0}>
        <div className="generate p-4">
          <div className="row">
            <div className="col-md-6">
              <div className="row gx-2 align-items-center">
                <div className="col-md-5">
                  <img src="assets/images/scratch_win.png" alt />
                </div>
                <div className="col-md-4">
                  <img src="assets/images/rst.png" alt />
                </div>
              </div>
              <div className="content text-start mt-4">
                Each Scratch &amp; Win ticket is unique and randomized. The ticket features 9 boxes, with 5 containing 50 CNX, 3 containing 100 CNX, and 1 containing 200 CNX, all randomly arranged. Select any 3 boxes to open, and the total CNX from the chosen boxes will be automatically credited to your wallet address.
              </div>                            
            </div>
            <div className="col-md-6">
              <div className="features">
                <div className="row g-3">
                  <div className="col-4">
                    <a href="#">
                      <div className="box" />
                    </a>
                  </div>
                  <div className="col-4">
                    <a href="#">
                      <div className="box" />
                    </a>
                  </div>
                  <div className="col-4">
                    <a href="#">
                      <div className="box" />
                    </a>
                  </div>
                  <div className="col-4">
                    <a href="#">
                      <div className="box" />
                    </a>
                  </div>
                  <div className="col-4">
                    <a href="#">
                      <div className="box" />
                    </a>
                  </div>
                  <div className="col-4">
                    <a href="#">
                      <div className="box" />
                    </a>
                  </div>
                  <div className="col-4">
                    <a href="#">
                      <div className="box" />
                    </a>
                  </div>
                  <div className="col-4">
                    <a href="#">
                      <div className="box" />
                    </a>
                  </div>
                  <div className="col-4">
                    <a href="#">
                      <div className="box" />
                    </a>
                  </div>
                </div>
                <div className="mt-4">
                  <a href="#" className="btn btn-primary py-3">
                    Scratch Now<br /><small>(01 Ticket)</small>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="try">
      <div className="box mb-3">
        <a href="#" className="close"><img src="assets/images/close.png" alt /></a>
        <a href="#" data-bs-toggle="modal" data-bs-target="#mydaz">
          <figure>
            <img src="assets/images/bot.png" alt />
          </figure>                
          Try Out Mydaz<br /> AI Trading Bot
        </a>
      </div>
      <div className="box">
        <a href="#" className="close"><img src="assets/images/close.png" alt /></a>
        <a href="#" data-bs-toggle="modal" data-bs-target="#farming">
          <figure>
            <img src="assets/images/farm.png" alt />
          </figure>
          Social<br /> Farming
        </a>
      </div>
    </div>
  </div>
  <nav className="fixed-bottom">
    <div className="container">
      <div className="row gx-2">
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_trade.svg" alt /></figure>
            Trade
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_nodes.svg" alt /></figure>
            Nodes
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_card.svg" alt /></figure>
            Crypto Card
          </a>
        </div>
        <div className="col">
          <a href="#" className="active">
            <figure><img src="assets/images/icn_play.svg" alt /></figure>
            Play
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icm_launch.svg" alt /></figure>
            LaunchPad
          </a>
        </div>
      </div>
    </div>
  </nav>
  {/* Modal Mydaz */}
  <div className="modal fade" id="mydaz" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="Mydaz" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-0">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="text-center mb-5">
            <figure>
              <img src="assets/images/mydaz.png" alt />
            </figure>
            <h4>You are going to enter A New Site</h4>
            <a href="#" className="btn btn-primary mt-4 rounded-pill">Enter</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Modal Farming */}
  <div className="modal fade" id="farming" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="Farming" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-0">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="social">
            <h4 className="text-center">Accumulated 5,000 Social Points,<br />
              To Receive a Scratch &amp; Win Ticket</h4>
            <div className="row head mb-3">
              <div className="col">
                Social Points Accumulated:<br />
                500
              </div>
              <div className="col-auto">
                <u>Redeem</u>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Follow Advantio on X<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Follow</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Share Advantio’s Official<br />
                  Launch Teaser Post<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Follow</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Purchase a Lottery Ticket<br />
                  <span>5000 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Purchase</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Share Advantio’s Sure<br />
                  Win Lottery Post<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Retweet</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Join Our Telegram<br />
                  Channel<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Join Now</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Purchase Genesis Nodes<br />
                  <span>15,000 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Purchase</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    )
};
export default Play;