import Header from "../component/Header";

const Cryptocard=()=>
{
    return(
        <>

        <Header/>
   <div>
  <div className="line" />
  <div className="container">
    <div className="cryptocard">
      <div className="row">
        <div className="col-12">
          <h2>Get Your Advantio Card Today!!</h2>
        </div>
        <div className="col-12">
          <div className="panel">
            <form>
              <div className="row align-items-center">
                <div className="col-md-3 mb-2">Email Address</div>
                <div className="col mb-2"><input className="form-control" type="text" placeholder="Email" /></div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-3 mb-2">Password</div>
                <div className="col-md-3 mb-2"><input className="form-control" type="password" placeholder="Password" /></div>
                <div className="col-md-3 mb-2">Confirm Password</div>
                <div className="col-md-3 mb-2"><input className="form-control" type="password" placeholder="Password" /></div>
              </div>
              <div className="form-check mb-2 d-none d-md-block">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked" />
                <label className="form-check-label" htmlFor="flexCheckChecked">By creating an account, I accept Advantio <a href="#">Terms &amp; Conditions</a> and <a href="#">Privacy Policy</a><br />
                  A Simple KYC form will be send to your email, please fill up to complete the sign up.</label>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <button type="submit" className="btn btn-orange">Sign Up</button>
                </div>
                <div className="col-md-6">
                  <button type="submit" className="btn btn-blue">Already Have An Account, Sign In</button>
                </div>
              </div>
              <div className="form-check mb-2 d-sm-block d-md-none">
                <input className="form-check-input" type="checkbox" defaultValue id="flexCheckChecked1" />
                <label className="form-check-label" htmlFor="flexCheckChecked">By creating an account, I accept Advantio <a href="#">Terms &amp; Conditions</a> and <a href="#">Privacy Policy</a></label>
              </div>
              <div className="simple d-sm-block d-md-none">
                A Simple KYC form will be send to your email, please fill up to complete the sign up.
              </div>
            </form>
          </div>
        </div>
        <div className="col-12">
          <div id="carouselHero" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
              <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={1} aria-label="Slide 2" />
              <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={2} aria-label="Slide 3" />
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="assets/images/bnr_01.jpg" alt />
              </div>
              <div className="carousel-item">
                <img src="assets/images/bnr_02.jpg" alt />
              </div>
              <div className="carousel-item">
                <img src="assets/images/bnr_03.jpg" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="account">
        <div className="row">
          <div className="col-12">
            <h2>Types Of Account</h2>
          </div>
          <div className="col-4">
            <div className="">
              <div className="item">
                <div className="plan">
                  <h3>Basic</h3>
                  <h4>Features</h4>
                  <dl>
                    <dt>Yearly administration fee</dt>
                    <dd>$20.00 USD</dd>
                    <dt>Card replacement fee</dt>
                    <dd>$20.00 USD</dd>
                    <dt>Fiat Top Up Fee</dt>
                    <dd>5%</dd>
                    <dt>Crypto Top Up Fee</dt>
                    <dd>3%</dd>
                    <dt>ATM Withdrawal Fee**</dt>
                    <dd>N/A</dd>
                    <dt>Check balance Fee (ATM)**</dt>
                    <dd>N/A</dd>
                    <dt>POS and Online Fees**</dt>
                    <dd>0%</dd>
                    <dt>ATM Withdrawal Limits</dt>
                    <dd>N/A</dd>
                    <dt>POS / Online Spending Limits</dt>
                    <dd>$20.00</dd>
                    <dt>Free Virtual Cards</dt>
                    <dd>1</dd>
                    <dt>Free Physical Cards</dt>
                    <dd>0</dd>
                    <dt>Transfer time</dt>
                    <dd>Banking Hours</dd>
                    <dt>Account to Account</dt>
                    <dd>1%</dd>
                    <dt>Account to External Bank Account</dt>
                    <dd>3%</dd>
                  </dl>
                </div>
              </div>
            </div>
            </div>
          <div className="col-4">
              <div className="item">
                <div className="plan">
                  <h3>Essential</h3>
                  <h4>Features</h4>
                  <dl>
                    <dt>Yearly administration fee</dt>
                    <dd>$20.00 USD</dd>
                    <dt>Card replacement fee</dt>
                    <dd>$20.00 USD</dd>
                    <dt>Fiat Top Up Fee</dt>
                    <dd>5%</dd>
                    <dt>Crypto Top Up Fee</dt>
                    <dd>3%</dd>
                    <dt>ATM Withdrawal Fee**</dt>
                    <dd>N/A</dd>
                    <dt>Check balance Fee (ATM)**</dt>
                    <dd>N/A</dd>
                    <dt>POS and Online Fees**</dt>
                    <dd>0%</dd>
                    <dt>ATM Withdrawal Limits</dt>
                    <dd>N/A</dd>
                    <dt>POS / Online Spending Limits</dt>
                    <dd>$20.00</dd>
                    <dt>Free Virtual Cards</dt>
                    <dd>1</dd>
                    <dt>Free Physical Cards</dt>
                    <dd>0</dd>
                    <dt>Transfer time</dt>
                    <dd>Banking Hours</dd>
                    <dt>Account to Account</dt>
                    <dd>1%</dd>
                    <dt>Account to External Bank Account</dt>
                    <dd>3%</dd>
                  </dl>
                </div>
              </div>
            </div>
          <div className="col-4">
              <div className="item">
                <div className="plan">
                  <h3>Pro</h3>
                  <h4>Features</h4>
                  <dl>
                    <dt>Yearly administration fee</dt>
                    <dd>$20.00 USD</dd>
                    <dt>Card replacement fee</dt>
                    <dd>$20.00 USD</dd>
                    <dt>Fiat Top Up Fee</dt>
                    <dd>5%</dd>
                    <dt>Crypto Top Up Fee</dt>
                    <dd>3%</dd>
                    <dt>ATM Withdrawal Fee**</dt>
                    <dd>N/A</dd>
                    <dt>Check balance Fee (ATM)**</dt>
                    <dd>N/A</dd>
                    <dt>POS and Online Fees**</dt>
                    <dd>0%</dd>
                    <dt>ATM Withdrawal Limits</dt>
                    <dd>N/A</dd>
                    <dt>POS / Online Spending Limits</dt>
                    <dd>$20.00</dd>
                    <dt>Free Virtual Cards</dt>
                    <dd>1</dd>
                    <dt>Free Physical Cards</dt>
                    <dd>0</dd>
                    <dt>Transfer time</dt>
                    <dd>Banking Hours</dd>
                    <dt>Account to Account</dt>
                    <dd>1%</dd>
                    <dt>Account to External Bank Account</dt>
                    <dd>3%</dd>
                  </dl>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className="try">
      <div className="box mb-3">
        <a href="#" className="close"><img src="assets/images/close.png" alt /></a>
        <a href="#" data-bs-toggle="modal" data-bs-target="#mydaz">
          <figure>
            <img src="assets/images/bot.png" alt />
          </figure>                
          Try Out Mydaz<br /> AI Trading Bot
        </a>
      </div>
      <div className="box">
        <a href="#" className="close"><img src="assets/images/close.png" alt /></a>
        <a href="#" data-bs-toggle="modal" data-bs-target="#farming">
          <figure>
            <img src="assets/images/farm.png" alt />
          </figure>
          Social<br /> Farming
        </a>
      </div>
    </div>
  </div>
  <nav className="fixed-bottom">
    <div className="container">
      <div className="row gx-2">
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_trade.svg" alt /></figure>
            Trade
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_nodes.svg" alt /></figure>
            Nodes
          </a>
        </div>
        <div className="col">
          <a href="#" className="active">
            <figure><img src="assets/images/icn_card.svg" alt /></figure>
            Crypto Card
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_play.svg" alt /></figure>
            Play
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icm_launch.svg" alt /></figure>
            LaunchPad
          </a>
        </div>
      </div>
    </div>
  </nav>
  {/* Modal Mydaz */}
  <div className="modal fade" id="mydaz" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="Mydaz" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-0">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="text-center mb-5">
            <figure>
              <img src="assets/images/mydaz.png" alt />
            </figure>
            <h4>You are going to enter A New Site</h4>
            <a href="#" className="btn btn-primary mt-4 rounded-pill">Enter</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Modal Farming */}
  <div className="modal fade" id="farming" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="Farming" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-0">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="social">
            <h4 className="text-center">Accumulated 5,000 Social Points,<br />
              To Receive a Scratch &amp; Win Ticket</h4>
            <div className="row head mb-3">
              <div className="col">
                Social Points Accumulated:<br />
                500
              </div>
              <div className="col-auto">
                <u>Redeem</u>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Follow Advantio on X<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Follow</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Share Advantio’s Official<br />
                  Launch Teaser Post<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Follow</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Purchase a Lottery Ticket<br />
                  <span>5000 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Purchase</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Share Advantio’s Sure<br />
                  Win Lottery Post<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Retweet</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Join Our Telegram<br />
                  Channel<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Join Now</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Purchase Genesis Nodes<br />
                  <span>15,000 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Purchase</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    )
};
export default Cryptocard;