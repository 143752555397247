const Header=()=>
{
    return(
        <>
       <nav className="navbar navbar-expand-lg">
  <div className="container">
    <a className="navbar-brand" href="#"><img src="assets/images/logo.png" alt /></a>           
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link active" href="Trade">Trade</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="Nodes">Nodes</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="Cryptocard">Crypto Card</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="Play">Play</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="Launchpad">LaunchPad</a>
        </li>
      </ul>                
    </div>
    <div className="d-flex gap-3">
      <div className="dropdown lang">
        <a className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="assets/images/globe.png" alt />
        </a>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item active" href="#">English</a></li>
          <li><a className="dropdown-item" href="#">简体中文</a></li>
          <li><a className="dropdown-item" href="#">繁体中文</a></li>
          <li><a className="dropdown-item" href="#">แบบไทย</a></li>
          <li><a className="dropdown-item" href="#">Tiếng Việt</a></li>
        </ul>
      </div>
      <a href="#" className="btn btn-primary px-4 rounded-pill" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">Connect Wallet</a>
    </div>
  </div>
</nav>

<div className="offcanvas offcanvas-end" data-bs-backdrop="static" tabIndex={-1} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">    
    <div className="offcanvas-header">
      <picture><img src="assets/images/logo.png" alt /></picture>
      <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close">Close</button>
    </div>
    <div className="offcanvas-body px-0">
      <ul className="navbar-nav justify-content-end flex-grow-1">
        <li className="nav-item">
          <a className="nav-link" href="Profile"><span><img src="assets/images/icn_profile.svg" alt /></span> My Profile</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="Myticket"><span><img src="assets/images/icn_ticket.svg" alt /></span> My Tickets</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#"><span><img src="assets/images/icn_disconnect.svg" alt /></span> Disconnect</a>
        </li>
      </ul>
      <div className="px-3">
        <figure>
          <img src="assets/images/logo.png" alt />
        </figure>
        <h4>Download</h4>
        <ul>
          <li><a href="#">Advantio Web3 Wallet</a></li>
        </ul>
        <h4>Social Media</h4>
        <ul>
          <li><a href="#">Twitter</a></li>
          <li><a href="#">Telegram</a></li>
          <li><a href="#">Facebook</a></li>
          <li><a href="#">Discord</a></li>
        </ul>
        <h4>Business</h4>
        <ul>
          <li><a href="#">Launchpad Merchant</a></li>
          <li><a href="#">Liquidity Providers</a></li>
        </ul>
        <h4>Support</h4>
        <ul>
          <li><a href="#">Get Help</a></li>
          <li><a href="#">Terms of Service</a></li>
          <li><a href="#">Rewards Terms</a></li>
        </ul>
        <h4>Developers</h4>
        <ul>
          <li><a href="#">Github</a></li>
          <li><a href="#">Documentation</a></li>
          <li><a href="#">Tokenomic</a></li>
        </ul>
      </div>
    </div>
  </div>
        </>
    )
};
export default Header;
