import Header from "../component/Header";

const Trade=()=>
{
    return(
        <>
          
    <Header/>
<div>
  <div className="line" />
  <div className="container">
    <div className="row">
      <div className="col">
        <div id="carouselHero" className="carousel slide carousel-fade" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={1} aria-label="Slide 2" />
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={2} aria-label="Slide 3" />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="assets/images/bnr_01.jpg" alt />
            </div>
            <div className="carousel-item">
              <img src="assets/images/bnr_02.jpg" alt />
            </div>
            <div className="carousel-item">
              <img src="assets/images/bnr_03.jpg" alt />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-10 col-lg-8 mx-auto">
        <ul className="nav nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="pills-swap-tab" data-bs-toggle="pill" data-bs-target="#pills-swap" type="button" role="tab" aria-controls="pills-swap" aria-selected="true">Swap</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="pills-buy-tab" data-bs-toggle="pill" data-bs-target="#pills-buy" type="button" role="tab" aria-controls="pills-buy" aria-selected="false">Buy Crypto</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="pills-cross-tab" data-bs-toggle="pill" data-bs-target="#pills-cross" type="button" role="tab" aria-controls="pills-cross" aria-selected="false">Cross Chain</button>
          </li>
        </ul>
        <div className="panel">
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-swap" role="tabpanel" aria-labelledby="pills-swap-tab" tabIndex={0}>
              <div className="row mb-3 align-items-center">
                <div className="col">
                  <ul className="nav nav-pills nav-justified" id="pills-tab-1" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="pills-market-tab" data-bs-toggle="pill" data-bs-target="#pills-market" type="button" role="tab" aria-controls="pills-market" aria-selected="true">Market</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-limit-tab" data-bs-toggle="pill" data-bs-target="#pills-limit" type="button" role="tab" aria-controls="pills-limit" aria-selected="false">Limit</button>
                    </li>
                  </ul>
                </div>
                <div className="col-1 ps-0">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#chart">
                    <img src="assets/images/insight.png" className="h-100 insite" alt />
                  </a>
                </div>
              </div>
              <div className="tab-content" id="pills-tabContent-1">
                <div className="tab-pane fade show active" id="pills-market" role="tabpanel" aria-labelledby="pills-market-tab" tabIndex={0}>
                  <div className="price py-2 mb-3">
                    <div className="row align-items-center">
                      <div className="col">Price</div>
                      <div className="col-2 col-md-1 p-0"><small>- -</small></div>
                    </div>
                  </div>
                  <div className="sell mb-3">
                    <div className="row">
                      <div className="col-12 col-md-auto">Sell</div>
                      <div className="col"><h1>0</h1></div>
                      <div className="col-auto crypto"><a href="#" data-bs-toggle="modal" data-bs-target="#selectToken"><img src="assets/images/cnx.png" alt /> CNX</a></div>
                    </div>                                    
                  </div>
                  <div className="col text-center mb-3">
                    <img src="assets/images/cvt.png" className="cv" alt />
                  </div>
                  <div className="buy mb-3">
                    <div className="row">
                      <div className="col-12 col-md-auto">Buy</div>
                      <div className="col"><h1>0</h1></div>
                      <div className="col-auto token"><a href="#" className="btn btn-select px-3 rounded-pill" data-bs-toggle="modal" data-bs-target="#selectToken">Select Token </a></div>
                    </div>                                    
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <a href="#" className="btn btn-primary">Swap</a>
                    </div>
                    <div className="col-12 col-md-6 fee">
                      <div className="row">
                        <div className="col">
                          Gas Fees<br />
                        </div>
                        <div className="col-3 text-center">- -</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          Slippage <img src="assets/images/cog.svg" className="ms-1" alt /><br />
                        </div>
                        <div className="col-3 text-center">0.5%</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          You will receive<br />
                        </div>
                        <div className="col-3 text-center">- -</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-limit" role="tabpanel" aria-labelledby="pills-limit-tab" tabIndex={0}>
                  <div className="price py-2 mb-3">
                    <div className="row">
                      <div className="col-auto">
                        When 1
                      </div>
                      <div className="col crypto">
                        <img src="assets/images/cnx.png" alt /> <small>CNX</small>
                      </div>
                    </div>
                  </div>
                  <div className="rate mb-3">
                    <div className="row">
                      <div className="col"><h1>0.0001</h1></div>
                      <div className="col-auto crypto"><img src="assets/images/usdt.png" className="me-2" alt /> USDT</div>
                    </div>
                    <div className="d-none d-md-block">
                      <a href className="badge active">Market</a> <a href className="badge">1%</a> <a href className="badge">5%</a> <a href className="badge">10%</a> 
                    </div>
                  </div>
                  <div className="sell mb-3">
                    <div className="row">
                      <div className="col-12 col-md-auto">Sell</div>
                      <div className="col"><h1>0</h1></div>
                      <div className="col-auto crypto"><a href="#" data-bs-toggle="modal" data-bs-target="#selectToken"><img src="assets/images/cnx.png" alt /> CNX</a></div>
                    </div>                                    
                  </div>
                  <div className="col text-center mb-3">
                    <img src="assets/images/cvt.png" className="cv" alt />
                  </div>
                  <div className="buy mb-3">
                    <div className="row">
                      <div className="col-12 col-md-auto">Buy</div>
                      <div className="col"><h1>0</h1></div>
                      <div className="col-auto crypto"><a href="#" data-bs-toggle="modal" data-bs-target="#selectToken"><img src="assets/images/usdt.png" alt /> USDT</a></div>
                    </div>                                    
                  </div>
                  <div className="price mb-3 d-none d-md-block">
                    <div className="row">
                      <div className="col">
                        Expiry &nbsp; <a href className="badge active">1 Day</a> <a href className="badge">7 Day</a> <a href className="badge">30 Day</a>
                      </div>
                      <div className="col warn">
                        <img src="assets/images/warning.png" alt />
                        Limits may not execute exactly when tokens reach the<br /> specified price. Learn more
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <a href="#" className="btn btn-primary">Swap</a>
                    </div>
                    <div className="col-12 col-md-6 fee">
                      <div className="row">
                        <div className="col">
                          Gas Fees<br />
                        </div>
                        <div className="col-3 text-center">- -</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          Slippage <img src="assets/images/cog.svg" className="ms-1" alt /><br />
                        </div>
                        <div className="col-3 text-center">0.5%</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          You will receive<br />
                        </div>
                        <div className="col-3 text-center">- -</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="pills-buy" role="tabpanel" aria-labelledby="pills-buy-tab" tabIndex={0}>
              <div className="col-12 mb-3">
                <div className="dropdown">
                  <button className="btn btn-outline-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Select Region <img src="assets/images/flag.png" alt />
                  </button>
                </div>
              </div>                            
              <div className="sell mb-3">
                <div className="row">
                  <div className="col-12 col-md-auto">Spend</div>
                  <div className="col"><h1>50</h1></div>
                  <div className="col-auto crypto"><a href="#" data-bs-toggle="modal" data-bs-target="#selectToken"><img src="assets/images/usa.png" alt /> USD</a></div>
                </div>                                    
              </div>
              <div className="col text-center mb-3">
                <img src="assets/images/cvt.png" className="cv" alt />
              </div>
              <div className="buy mb-3">
                <div className="row">
                  <div className="col-12 col-md-auto">Receive</div>
                  <div className="col"><h1>48.75</h1></div>
                  <div className="col-auto crypto"><a href="#" data-bs-toggle="modal" data-bs-target="#selectToken"><img src="assets/images/usdt.png" alt /> USDT</a></div>
                </div>                                    
              </div>
              <div className="wallet">
                <div className="row align-items-center mb-3">
                  <div className="col-12 col-md-4">
                    Your Wallet Address
                  </div>
                  <div className="col-12 col-md-8">
                    <input className="form-control adres" type="text" defaultValue="0xC04cf7FBc6839F595EF279CccfDc4f50082F61b6" />                                    
                  </div>
                </div>
                <div className="row align-items-center mb-3">
                  <div className="col-12 col-md-4">
                    Select Network
                  </div>
                  <div className="col-12 col-md-8">
                    <input className="form-control smart" type="text" defaultValue="Binance Smart Chain" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-4">
                    Payment Method
                  </div>
                  <div className="col-12 col-md-8">
                    <div className="d-flex gap-2">
                      <img src="assets/images/visa.png" alt /> <img src="assets/images/gpay.png" alt />
                    </div>
                  </div>
                </div>
              </div>
              <a href="#" className="btn btn-primary btn-lg">Continues</a>
            </div>
            <div className="tab-pane fade" id="pills-cross" role="tabpanel" aria-labelledby="pills-cross-tab" tabIndex={0}>
              <div className="sell mb-3">
                <div className="row">
                  <div className="col-12 col-md-auto">Amount</div>
                  <div className="col"><h1>0</h1></div>
                  <div className="col-auto token">
                    <div className="d-grid gap-2">
                      <a href="#" className="btn btn-select px-3 rounded-pill" data-bs-toggle="modal" data-bs-target="#selectToken">Select Token</a>
                      <a href="#" className="btn btn-select px-3 rounded-pill">Network</a>
                    </div>
                  </div>
                </div>                                    
              </div>
              <div className="col text-center mb-3 position-relative">
                <img src="assets/images/cvt.png" className="cv" alt />
                <div className="position-absolute top-0 end-0 mt-1">
                  <button type="button" className="btn btn-connect px-3">Connect Wallet</button>
                </div>
              </div>
              <div className="buy mb-3">
                <div className="row">
                  <div className="col-12 col-md-auto">Amount</div>
                  <div className="col"><h1>0</h1></div>
                  <div className="col-auto token">
                    <div className="d-grid gap-2">
                      <a href="#" className="btn btn-select px-3 rounded-pill" data-bs-toggle="modal" data-bs-target="#selectToken">Select Token</a>
                      <a href="#" className="btn btn-select px-3 rounded-pill">Network</a>
                    </div>
                  </div>
                </div>                                    
              </div>
              <div className="row">
                <div className="col-12 col-md-6 pb-1">
                  <a href="#" className="btn btn-primary">Cross</a>
                </div>
                <div className="col-12 col-md-6 fee">
                  <div className="row">
                    <div className="col">
                      Gas Fees<br />
                    </div>
                    <div className="col-3 text-center">- -</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      Cross Fees<br />
                    </div>
                    <div className="col-3 text-center">- -</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      Slippage <img src="assets/images/cog.svg" className="ms-1" alt /><br />
                    </div>
                    <div className="col-3 text-center">0.5%</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      You will receive<br />
                    </div>
                    <div className="col-3 text-center">- -</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>            
    </div>
    <div className="try">
      <div className="box mb-3">
        <a href="#" className="close"><img src="assets/images/close.png" alt /></a>
        <a href="#" data-bs-toggle="modal" data-bs-target="#mydaz">
          <figure>
            <img src="assets/images/bot.png" alt />
          </figure>                
          Try Out Mydaz<br /> AI Trading Bot
        </a>
      </div>
      <div className="box">
        <a href="#" className="close"><img src="assets/images/close.png" alt /></a>
        <a href="#" data-bs-toggle="modal" data-bs-target="#farming">
          <figure>
            <img src="assets/images/farm.png" alt />
          </figure>
          Social<br /> Farming
        </a>
      </div>
    </div>
  </div>
  <nav className="fixed-bottom">
    <div className="container">
      <div className="row gx-2">
        <div className="col">
          <a href="#" className="active">
            <figure><img src="assets/images/icn_trade.svg" alt /></figure>
            Trade
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_nodes.svg" alt /></figure>
            Nodes
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_card.svg" alt /></figure>
            Crypto Card
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_play.svg" alt /></figure>
            Play
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icm_launch.svg" alt /></figure>
            LaunchPad
          </a>
        </div>
      </div>
    </div>
  </nav>
  {/* Modal Token */}
  <div className="modal fade" id="selectToken" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="Select Token" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-0">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <h4>Select a Token</h4>
          <div className="my-4">
            <input className="form-control" type="text" placeholder="Search Name or Paste Address" />
          </div>
          <h4>Common Tokens</h4>
          <div className="row gx-3 mb-3 token">
            <div className="col-3">
              <img src="assets/images/bnb.png" alt /> BNB
            </div>
            <div className="col-3">
              <img src="assets/images/usdt.png" alt /> USDT
            </div>
            <div className="col-3">
              <img src="assets/images/cnx.png" alt /> CNX
            </div>
          </div>
          <div className="row align-items-center mb-3">
            <div className="col-auto">
              <img src="assets/images/bnb.png" alt />
            </div>
            <div className="col">
              BNB<br />
              Binance Chain Native Token
            </div>
            <div className="col-auto">
              0
            </div>
          </div>
          <div className="row align-items-center mb-3">
            <div className="col-auto">
              <img src="assets/images/usdt.png" alt />
            </div>
            <div className="col">
              USDT<br />
              Tether stablecoin
            </div>
            <div className="col-auto">
              0
            </div>
          </div>
          <div className="row align-items-center mb-3">
            <div className="col-auto">
              <img src="assets/images/cnx.png" width={52} alt />
            </div>
            <div className="col">
              CNX<br />
              Coinex Native Token
            </div>
            <div className="col-auto">
              0
            </div>
          </div>
          <br /> <br /> <br />
        </div>
      </div>
    </div>
  </div>
  {/* Modal Mydaz */}
  <div className="modal fade" id="mydaz" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="Mydaz" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-0">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="text-center mb-5">
            <figure>
              <img src="assets/images/mydaz.png" alt />
            </figure>
            <h4>You are going to enter A New Site</h4>
            <a href="#" className="btn btn-primary mt-4 rounded-pill">Enter</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Modal Farming */}
  <div className="modal fade" id="farming" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="Farming" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-0">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="social">
            <h4 className="text-center">Accumulated 5,000 Social Points,<br />
              To Receive a Scratch &amp; Win Ticket</h4>
            <div className="row head mb-3">
              <div className="col">
                Social Points Accumulated:<br />
                500
              </div>
              <div className="col-auto">
                <u>Redeem</u>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Follow Advantio on X<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Follow</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Share Advantio’s Official<br />
                  Launch Teaser Post<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Follow</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Purchase a Lottery Ticket<br />
                  <span>5000 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Purchase</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Share Advantio’s Sure<br />
                  Win Lottery Post<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Retweet</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Join Our Telegram<br />
                  Channel<br />
                  <span>500 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Join Now</a>
                </div>
              </div>
            </div>
            <div className="follow">
              <div className="row align-items-center">
                <div className="col">
                  Purchase Genesis Nodes<br />
                  <span>15,000 Social Points</span>
                </div>
                <div className="col-auto">
                  <a href="#" className="btn btn-follow rounded-pill">Purchase</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Modal Chart */}
  <div className="modal fade" id="chart" data-bs-backdrop="static" tabIndex={-1} aria-labelledby="Chart" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header border-0">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="chart">
            <p><img src="assets/images/cnx.png" width={34} alt /> <img src="assets/images/usdt.png" width={34} className="me-3" alt /> CNX/USDT</p>
            <h2>0.0012345 <small>CNX/USDT <span>+23.45%</span></small></h2>
            <span className="date">Oct 23, 2024 12:36 PM</span>
            <div className="row gx-0 mt-3">
              <div className="col-8">
                <div className="frame">
                  <div className="row gx-2">
                    <div className="col"><a className="active">1H</a></div>
                    <div className="col"><a>24H</a></div>
                    <div className="col"><a>1W</a></div>
                    <div className="col"><a>1M</a></div>
                    <div className="col"><a>1Y</a></div>
                  </div>
                </div>
                <div className="topspc">
                  <img src="assets/images/chart.png" alt />
                </div>
              </div>
              <div className="col-4">
                <h3>0.001600</h3>
                <h3>0.001500</h3>
                <h3>0.001400</h3>
                <h3>0.001300</h3>
                <h3 className="grn">0.0012345</h3>
                <h3>0.001200</h3>
                <h3>0.001100</h3>
                <h3>0.001000</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    )
};
export default Trade;
    