import Header from "../component/Header";

const Myticket=()=>
{
    return(
        <>
   <Header/>
 <div>
  <div className="line" />
  <div className="container">
    <div className="row">
      <div className="col-12 my-4 home">
        <a href="#" className="btn btn-primary px-4 rounded-pill">Back to home</a>
      </div>
      <div className="col-12">
        <div id="carouselHero" className="carousel slide mt-0 carousel-fade" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={1} aria-label="Slide 2" />
            <button type="button" data-bs-target="#carouselHero" data-bs-slide-to={2} aria-label="Slide 3" />
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="assets/images/bnr_01.jpg" alt />
            </div>
            <div className="carousel-item">
              <img src="assets/images/bnr_02.jpg" alt />
            </div>
            <div className="carousel-item">
              <img src="assets/images/bnr_03.jpg" alt />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-8 col-lg-6 mx-auto">
        <ul className="nav nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="pills-lottery-tab" data-bs-toggle="pill" data-bs-target="#pills-lottery" type="button" role="tab" aria-controls="pills-lottery" aria-selected="true">Lottery</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="pills-scratch-tab" data-bs-toggle="pill" data-bs-target="#pills-scratch" type="button" role="tab" aria-controls="pills-scratch" aria-selected="false" tabIndex={-1}>Scratch &amp; Win</button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-lottery" role="tabpanel" aria-labelledby="pills-lottery-tab" tabIndex={0}>
            <div className="card">
              <div className="row align-items-center">
                <div className="col-8">
                  <span>24-10-2024</span> <span>13:00:00</span>
                  <h3>LOTTERY NO: 0A55X</h3>
                  <p>OPENING: 25-10-2024 20:00:00</p>
                </div>
                <div className="col-4">
                  <a href="#" className="btn btn-primary rounded-pill">Await</a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="row align-items-center">
                <div className="col-8">
                  <span>24-10-2024</span> <span>13:00:24</span>
                  <h3>LOTTERY NO: 18N3D</h3>
                  <p>OPENING: 25-10-2024 20:00:00</p>
                </div>
                <div className="col-4">
                  <a href="#" className="btn btn-primary rounded-pill">Await</a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="row align-items-center">
                <div className="col-8">
                  <span>24-10-2024</span> <span>13:01:06</span>
                  <h3>LOTTERY NO: C6A82</h3>
                  <p>OPENING: 25-10-2024 20:00:00</p>
                </div>
                <div className="col-4">
                  <a href="#" className="btn btn-primary rounded-pill">Await</a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="row align-items-center">
                <div className="col-8">
                  <span>24-10-2024</span> <span>18:01:06</span>
                  <h3 className="org">LOTTERY NO: X1AD4</h3>
                  <p>OPENING: 24-10-2024 20:00:00</p>
                </div>
                <div className="col-4">
                  <a href="#" className="btn btn-primary rounded-pill">Claim</a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="row align-items-center">
                <div className="col-8">
                  <span>24-10-2024</span> <span>18:01:06</span>
                  <h3 className="org">LOTTERY NO: H29JK</h3>
                  <p>OPENING: 24-10-2024 20:00:00</p>
                </div>
                <div className="col-4">
                  <a href="#" className="btn btn-primary rounded-pill">Claim</a>
                </div>
              </div>
            </div>
            <div className="row align-items-center page">
              <div className="col-4 col-md-3">
                <a href="#" className="btn btn-primary rounded-pill">Previous</a>
              </div>
              <div className="col">1/10</div>
              <div className="col-4 col-md-3">
                <a href="#" className="btn btn-primary rounded-pill">Next</a>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="pills-scratch" role="tabpanel" aria-labelledby="pills-scratch-tab" tabIndex={0}>
            <div className="card">
              <div className="row align-items-center">
                <div className="col-8">
                  <span>24-10-2024</span> <span>13:00:00</span>
                  <h3 className="org">TOTAL WINNING 350 CNX</h3>
                </div>
                <div className="col-4">
                  <a href="#" className="btn btn-primary rounded-pill">Claim</a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="row align-items-center">
                <div className="col-8">
                  <span>24-10-2024</span> <span>13:00:00</span>
                  <h3 className="org">TOTAL WINNING 350 CNX</h3>
                </div>
                <div className="col-4">
                  <a href="#" className="btn btn-primary rounded-pill">Claim</a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="row align-items-center">
                <div className="col-8">
                  <span>24-10-2024</span> <span>13:00:00</span>
                  <h3 className="org">TOTAL WINNING 350 CNX</h3>
                </div>
                <div className="col-4">
                  <a href="#" className="btn btn-primary rounded-pill">Claim</a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="row align-items-center">
                <div className="col-8">
                  <span>24-10-2024</span> <span>13:00:00</span>
                  <h3 className="org">TOTAL WINNING 350 CNX</h3>
                </div>
                <div className="col-4">
                  <a href="#" className="btn btn-primary rounded-pill">Claim</a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="row align-items-center">
                <div className="col-8">
                  <span>24-10-2024</span> <span>13:00:00</span>
                  <h3 className="org">TOTAL WINNING 350 CNX</h3>
                </div>
                <div className="col-4">
                  <a href="#" className="btn btn-primary rounded-pill">Claim</a>
                </div>
              </div>
            </div>
            <div className="row align-items-center page">
              <div className="col-4 col-md-3">
                <a href="#" className="btn btn-primary rounded-pill">Previous</a>
              </div>
              <div className="col">1/10</div>
              <div className="col-4 col-md-3">
                <a href="#" className="btn btn-primary rounded-pill">Next</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav className="fixed-bottom">
    <div className="container">
      <div className="row gx-2">
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_trade.svg" alt /></figure>
            Trade
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_nodes.svg" alt /></figure>
            Nodes
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_card.svg" alt /></figure>
            Crypto Card
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icn_play.svg" alt /></figure>
            Play
          </a>
        </div>
        <div className="col">
          <a href="#">
            <figure><img src="assets/images/icm_launch.svg" alt /></figure>
            LaunchPad
          </a>
        </div>
      </div>
    </div>
  </nav>
</div>

        </>
    )
};
export default Myticket;
    